/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

export class Dbg {
  private static replacer() {
    const seen = new WeakSet();
    return (_: string, value: any) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  pritty(name: string, input: any) {
    console.group(name);
    console.log(JSON.stringify(input, Dbg.replacer(), 2));
    console.groupEnd();
  }

  count(name: string, input: any) {
    console.group(name);
    console.count(name);
    console.log(JSON.stringify(input, null, 2));
    console.groupEnd();
  }
}
