import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ThreadTypeService } from 'services/thread-type-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class ThreadTypeDetail {
  private threadtype: Models.ThreadType;

  constructor(
    private threadTypeService: ThreadTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.threadTypeService
      .get(params.Id)
      .then((threadType) => {
        this.threadtype = threadType;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateThreadType() {
    this.threadTypeService
      .put(this.threadtype, this.threadtype.Id)
      .then((res) => {
        this.eventAggregator.publish('threadTypeListReset', 1);
        this.toastService.showSuccess('threadtype.updated');
        this.router.navigateToRoute('thread-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteThreadType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.threadTypeService
          .delete(this.threadtype.Id)
          .then((res) => {
            this.eventAggregator.publish('threadTypeListReset', 1);
            this.toastService.showSuccess('threadtype.deleted');
            this.router.navigateToRoute('thread-type-list');
          })
          .catch((err) => this.errorService.handleError(err));
        }
      );
  }

  private gotoParentView() {
    this.eventAggregator.publish('threadTypeListReset', 1);
    this.router.navigateToRoute('thread-type-list');
  }
}
