import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ThreadTypeService } from 'services/thread-type-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class ThreadTypeNew {
  private threadtype: Models.ThreadType = new Models.ThreadType();

  constructor(
    private threadTypeService: ThreadTypeService,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  private createThreadType() {
    this.threadTypeService
      .post(this.threadtype)
      .then((res) => {
        this.eventAggregator.publish('threadTypeListReset', 1);
        this.toastService.showSuccess('threadtype.created');
        this.router.navigateToRoute('thread-type-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
