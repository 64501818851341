import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { StrapTypeService } from 'services/strap-type-service';

@autoinject
export class StrapTypeNew {
  private straptype: Models.StrapType = new Models.StrapType();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private strapTypeService: StrapTypeService
  ) {}

  private createStrapType() {
    this.strapTypeService
      .post(this.straptype)
      .then((res) => {
        this.eventAggregator.publish('strapTypeListReset', 1);
        this.toastService.showSuccess('straptype.created');
        this.router.navigateToRoute('strap-type-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
