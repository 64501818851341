import { autoinject, observable } from 'aurelia-framework';
import { connectTo } from 'aurelia-store';
import { ApplicationState } from 'lib/state';

export type TableHeader<T> = {
  key: Partial<keyof T>;
  label: string;
  sortable: boolean;
};

@autoinject
@connectTo()
export class TableContent {
  @observable
  protected state: ApplicationState;

  stateChanged() {
    this.setTableClass();
  }

  // table table--compact table--zebra table--vertical-lines
  constructor(private element: Element) {
    element.querySelector('.table')?.classList.add('table', 'table-striped', 'table-hover');
  }

  attached() {
    this.setTableClass();
  }

  private setTableClass() {
    const table = this.element.querySelector('.table');
    if (!table) return;
    table.classList.remove('table--ultracompact', 'table--compact', 'table--zebra', 'table--vertical-lines');

    const classlist = [];

    if (this.state.listSettings.tableViewType === 2) {
      classlist.push('table--zebra');
    }

    if (this.state.listSettings.tableMargin === 3) {
      classlist.push('table--ultracompact');
    } else if (this.state.listSettings.tableMargin === 2) {
      classlist.push('table--compact');
    }

    if (this.state.listSettings.tableViewVerticalLines) {
      classlist.push('table--vertical-lines');
    }

    table.classList.add(...classlist);
  }
}
