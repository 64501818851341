import { AccountService } from 'services/account-service';
import { AuthorizeStep } from './../../authorizeStep';
import { Router } from 'aurelia-router';
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger("no-access");

@autoinject
export class NoAccess {  
  private waitPeriodOver: boolean = false;

  private subscription: Subscription;

  constructor(private eventAggregator: EventAggregator, private router: Router, private accountService: AccountService) {    

  }

  activate() {
    this.subscription = this.eventAggregator.subscribe('roles-loaded', () => {
      logger.debug('Roles loaded, navigate to default view');
      //this.router.navigate('');
    });

    // dont show the "no access" page yet, wait a 5 seconds to see if the roles load first
    // if they are not already loaded..
    if (!AuthorizeStep.auth.roles) {
      setTimeout(() => {
        this.waitPeriodOver = true;
        this.accountService.clearAuthToken();
      }, 10000);
    }
  }

  private deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }
  
}
