import { ErrorService } from 'services/error-service';
import { autoinject, BindingEngine } from 'aurelia-framework';
import { UserService } from 'services/user-service';
import { UserModels } from '../../models/UserModels';

@autoinject
export class UserList {
  private users: Array<UserModels.User>;

  filteredUsers: Array<UserModels.User>;

  private filters = {
    searchText: null,
  };

  ready: boolean = false;

  constructor(
    private userService: UserService,
    private errorService: ErrorService,
    private bindingEngine: BindingEngine
  ) {}

  attached() {
    this.bindingEngine
      .propertyObserver(this.filters, 'searchText')
      .subscribe((newValue, oldValue) => {
        this.filterValueChanged('searchText', newValue, oldValue);
      });

    this.userService
      .getAll()
      .then((res) => {
        this.filteredUsers = res;
        this.users = res;
        this.ready = true;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private filterValueChanged(_: string, newValue: string, oldValue: string) {
    if (!newValue) this.filteredUsers = this.users;

    if (newValue === oldValue) {
      return;
    }

    this.filteredUsers = this.users?.filter(
      (t) => t.Name.toLowerCase().includes(newValue.toLowerCase()) === true
    );
  }
}
