import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { StrapTypeService } from 'services/strap-type-service';

@autoinject
export class StrapTypeDetail {
  private straptype: Models.StrapType;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private strapTypeService: StrapTypeService,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.strapTypeService
      .get(params.Id)
      .then( (res) => {
        this.straptype = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateStrapType() {
    this.strapTypeService
      .put(this.straptype, this.straptype.Id)
      .then((res) => {
        this.eventAggregator.publish('strapTypeListReset', 1);
        this.toastService.showSuccess('straptype.updated');
        this.router.navigateToRoute('strap-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteStrapType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.strapTypeService
          .delete(this.straptype.Id)
          .then((res) => {
            this.eventAggregator.publish('strapTypeListReset', 1);
            this.toastService.showSuccess('straptype.deleted');
            this.router.navigateToRoute('strap-type-list');
          })
          .catch((err) => this.errorService.handleError(err));
        }
      );
  }

  private gotoParentView() {
    this.eventAggregator.publish('strapTypeListReset', 1);
    this.router.navigateToRoute('strap-type-list');
  }
}
