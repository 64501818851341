import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, BindingEngine } from 'aurelia-framework';
import { Models } from 'models/core';
import * as moment from 'moment';
import { ErrorService } from 'services/error-service';
import { InternalDepartmentService } from 'services/internal-department-service';
import { SegmentService } from 'services/segment-service';
import { UserService } from 'services/user-service';
import { WeekReportService } from 'services/week-report-service';
import { AuthorizeStep } from '../../authorizeStep';

@autoinject
export class WeekReportList {

    private weekReports: Array<Models.WeekReport>;
    private filters: any = {};
    private isLeader: boolean = false;
    private segments: Array<Models.Segment>;
    private internalDepartments: Array<Models.InternalDepartment>;

    constructor(
      private errorService: ErrorService,
      private weekReportService: WeekReportService,
      private eventAggregator: EventAggregator,
      private bindingEngine: BindingEngine,
      private segmentService: SegmentService,
      private internalDepartmentService: InternalDepartmentService,
      private userService: UserService
    ) {

    }

    private attached() {

      this.isLeader = AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('Leader') !== -1;

      let sessionFilters = JSON.parse(sessionStorage.getItem('WEEK_REPORT_FILTERS'));
      
      if (sessionFilters) {
        this.filters = sessionFilters;
      } else {
        this.filters.ReportYearFrom = new Date().getFullYear();
        this.filters.ReportWeekFrom = moment.default().format('W');
      }

      this.eventAggregator.subscribe('weekReportListReset', (res) => {
        this.getWeekReports();
      });
      
      this.bindingEngine
      .propertyObserver(this.filters, 'ReportYearFrom')
      .subscribe((newValue, oldValue) => {
        this.setFilterYear();
      });

      this.bindingEngine
      .propertyObserver(this.filters, 'ReportWeekFrom')
      .subscribe((newValue, oldValue) => {
        this.setFilterWeek();
      });

      this.bindingEngine
      .propertyObserver(this.filters, 'ReportYearTo')
      .subscribe((newValue, oldValue) => {
        this.setFilterYear();
      });

      this.bindingEngine
      .propertyObserver(this.filters, 'ReportWeekTo')
      .subscribe((newValue, oldValue) => {
        this.setFilterWeek();
      });

      this.userService
      .getCurrentUser()
      .then(async (res) => {

        let segments = await this.segmentService.getAllCached();
        let internalDepartments = await this.internalDepartmentService.getAllCached();

        this.segments = segments.filter((x) => !x.IsDeleted);
        this.internalDepartments = internalDepartments.filter((x) => !x.IsDeleted);

        if (!sessionFilters) {
          if (this.segments.filter((x) => x.LeaderId === res.Id).length === 1) {
            this.filters.SegmentId = this.segments.filter((x) => x.LeaderId === res.Id)[0].Id;
          }

          if (this.internalDepartments.filter((x) => x.LeaderId === res.Id).length === 1) {
            this.filters.InternalDepartmentId = this.internalDepartments.filter((x) => x.LeaderId === res.Id)[0].Id;
          }
        }

        this.getWeekReports();

      })
      .catch((err) => this.errorService.handleError(err));

    }

    private addFilter(filterString: string, filter: string){
      if (filterString === '') {
        filterString = `$filter=${filter}`;
      } else {
        filterString += ` and ${filter}`;
      }
      return filterString;
    }

    private sanitizeFilters() {
      for (const field of Object.keys(this.filters)) {
        if (this.filters[field] && this.filters[field] == '') {
          this.filters[field] = null;
        }
      }
    }

    private getWeekReports() {

      this.sanitizeFilters();

      sessionStorage.setItem('WEEK_REPORT_FILTERS', JSON.stringify(this.filters));

      let filterString = '';

      if (this.filters.SegmentId) {
        filterString = this.addFilter(filterString, `SegmentId eq ${this.filters.SegmentId}`);
      }
      
      if (this.filters.InternalDepartmentId) {
        filterString = this.addFilter(filterString, `InternalDepartmentId eq ${this.filters.InternalDepartmentId}`);
      }

      if (this.filters.ReportYearFrom && this.filters.ReportWeekFrom && this.filters.ReportYearFrom == this.filters.ReportYearTo && this.filters.ReportWeekFrom == this.filters.ReportWeekTo) {
        filterString = this.addFilter(filterString, `ReportYear eq ${this.filters.ReportYearFrom} and ReportWeek eq ${this.filters.ReportWeekFrom}`);
      } else if (this.filters.ReportYearFrom && this.filters.ReportWeekFrom && this.filters.ReportYearTo && this.filters.ReportWeekTo) {
        filterString = this.addFilter(filterString, `(((ReportYear ge ${this.filters.ReportYearFrom} and ReportWeek ge ${this.filters.ReportWeekFrom}) or ReportYear gt ${this.filters.ReportYearFrom}) 
            and ((ReportYear le ${this.filters.ReportYearTo} and ReportWeek le ${this.filters.ReportWeekTo}) or ReportYear lt ${this.filters.ReportYearTo}))`);
      } else if (this.filters.ReportYearFrom && this.filters.ReportWeekFrom) {
        filterString = this.addFilter(filterString, `((ReportYear ge ${this.filters.ReportYearFrom} and ReportWeek ge ${this.filters.ReportWeekFrom}) or ReportYear gt ${this.filters.ReportYearFrom})`);
      } else if (this.filters.ReportYearTo && this.filters.ReportWeekTo) {
        filterString = this.addFilter(filterString, `((ReportYear le ${this.filters.ReportYearTo} and ReportWeek le ${this.filters.ReportWeekTo}) or ReportYear lt ${this.filters.ReportYearTo})`);
      }
      
      this.weekReportService
      .getAll('?' + filterString)
      .then((res) => {
        this.weekReports = res;
      })
      .catch((err) => this.errorService.handleError(err));
    }

    private setFilterSegmentId(event) {
      this.filters.SegmentId = event ? event.detail.value : null;
      this.getWeekReports();
    }

    private setFilterInternalDepartmentId(event) {
      this.filters.InternalDepartmentId = event ? event.detail.value : null;
      this.getWeekReports();
    }

    private setFilterYear() {
      this.getWeekReports();
    }

    private setFilterWeek() {
      this.getWeekReports();
    }

    private exportWeekReports() {
      this.weekReportService.exportList(this.filters.SegmentId, this.filters.InternalDepartmentId, this.filters.ReportYear, this.filters.ReportWeek);
    }

    private fromPreviousWeek() {
      if (parseInt(this.filters.ReportWeekFrom) === 1) {
        // go to last year - find last week of last year first
        this.filters.ReportYearFrom = parseInt(this.filters.ReportYearFrom) - 1;
        let weeksLastYear = this.getWeeksInYear(parseInt(this.filters.ReportYearFrom));
      
        this.filters.ReportWeekTo = weeksLastYear;

      } else {
        this.filters.ReportWeekFrom = parseInt(this.filters.ReportWeekFrom) - 1;
      }
    }

    private toPreviousWeek() {
      if (parseInt(this.filters.ReportWeekTo) === 1) {
        // go to last year - find last week of last year first
        this.filters.ReportWeekTo = parseInt(this.filters.ReportWeekTo) - 1;
        let weeksLastYear = this.getWeeksInYear(parseInt(this.filters.ReportYearFrom));
      
        this.filters.ReportWeekFrom = weeksLastYear;

      } else {
        this.filters.ReportWeekTo = parseInt(this.filters.ReportWeekTo) - 1;
      }

      this.sanitizeFilters();
      
      if (this.filters.ReportYearFrom && this.filters.ReportWeekFrom && this.filters.ReportYearTo && this.filters.ReportWeekTo) {      
        if((this.filters.ReportYearFrom + '-' + this.filters.ReportWeekFrom) > (this.filters.ReportYearTo + '-' + this.filters.ReportWeekTo)) {
          this.filters.ReportYearFrom = this.filters.ReportYearTo;
          this.filters.ReportWeekFrom = this.filters.ReportWeekTo;
        }
      }
    }

    private fromNextWeek() {
      let weeksCurrentYear = this.getWeeksInYear(parseInt(this.filters.ReportYearFrom));
      
      if (parseInt(this.filters.ReportWeekFrom) === weeksCurrentYear) {
        // go to next year
        this.filters.ReportYearFrom = parseInt(this.filters.ReportYearFrom) + 1;
        this.filters.ReportWeekFrom = 1;
      } else {
        this.filters.ReportWeekFrom = parseInt(this.filters.ReportWeekFrom) + 1;
      }

      this.sanitizeFilters();

      if (this.filters.ReportYearFrom && this.filters.ReportWeekFrom && this.filters.ReportYearTo && this.filters.ReportWeekTo) {
        if((this.filters.ReportYearFrom + '-' + this.filters.ReportWeekFrom) > (this.filters.ReportYearTo + '-' + this.filters.ReportWeekTo)) {
          this.filters.ReportYearTo = this.filters.ReportYearFrom;
          this.filters.ReportWeekTo = this.filters.ReportWeekFrom;
        }
      }
    }

    private toNextWeek() {
      let weeksCurrentYear = this.getWeeksInYear(parseInt(this.filters.ReportYearTo));
      
      this.sanitizeFilters();

      if (!this.filters.ReportWeekTo) {
        this.filters.ReportWeekTo = this.filters.ReportWeekFrom;
      }
      if (!this.filters.ReportYearTo) {
        this.filters.ReportYearTo = this.filters.ReportYearFrom;
      }

      if (parseInt(this.filters.ReportWeekTo) === weeksCurrentYear) {
        // go to next year
        this.filters.ReportYearTo = parseInt(this.filters.ReportYearTo) + 1;
        this.filters.ReportWeekTo = 1;
      } else {
        this.filters.ReportWeekTo = parseInt(this.filters.ReportWeekTo) + 1;
      }
    }

    private nextWeek() {
      let weeksCurrentYear = this.getWeeksInYear(parseInt(this.filters.ReportYear));
      
      if (parseInt(this.filters.ReportWeek) === weeksCurrentYear) {
        // go to next year
        this.filters.ReportYear = parseInt(this.filters.ReportYear) + 1;
        this.filters.ReportWeek = 1;
      } else {
        this.filters.ReportWeek = parseInt(this.filters.ReportWeek) + 1;
      }
    }

    private getWeeksInYear(year: number): number {
      let date = moment.default(year + '-12-31');

      while (true) {
        let weekNumber = this.getWeekNumber(date);
        if (weekNumber === 1) {
          date = moment.default(date).add(-1, 'days');
        } else {
          return weekNumber;
        }
      }
    }

    private getWeekNumber(date) {
      return moment.default(date).isoWeek();
    }



}
