import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { UserModels } from 'models/UserModels';
import { ErrorService } from 'services/error-service';
import { UserService } from 'services/user-service';
import { Utility } from 'utility';

@autoinject
export class UserNew {

  private user: UserModels.User = new UserModels.User();
  private originalObject: any = {};
  private saving: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private i18n: I18N,
    private validationController: ValidationController,
    private validator: Validator,
    private utility: Utility,
    private dialogService: DialogService,
    private prompt: Prompt,
    private errorService: ErrorService
  ) {
    ValidationRules
      .ensure('Name').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('Email').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('Email').email().withMessage(this.i18n.tr('general.incorrectEmailFormat'))
      .on(this.user);
  }

  private attached() {
    this.originalObject = JSON.parse(JSON.stringify(this.user));
  }

  private createUser() {
    this.saving = true;
    this.userService.post(this.user)
      .then((createdUser) => {
        this.originalObject = null;
        this.router.navigateToRoute('user-detail', { Id: createdUser.Id });
        this.saving = false;
      }).catch((err) => {
        this.saving = false;
        this.errorService.handleError(err);
      });
  }

  private validateBeforeSave() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.user)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.createUser();
        }
      });
  }

  private canDeactivate() {
    if (this.originalObject && !this.utility.areEqual(this.user, this.originalObject)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: {
          header: 'dialog.subFormOpenHeading',
          message: 'dialog.subFormOpenMessage',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel'  },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.continue'  }
          }
        }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  }

}
