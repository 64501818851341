import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { RoleModels } from 'models/RoleModels';
import { UserModels } from 'models/UserModels';
import { MooringStationService } from 'services';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { ProducerService } from 'services/producer-service';
import { RoleService } from 'services/role-service';
import { ServiceStationService } from 'services/service-station-service';
import { ToastService } from 'services/toast-service';
import { UserService } from 'services/user-service';
import { Utility } from 'utility';

type NameTranslated = { Name: string; NameTranslated: string };
type TranslatedRole = RoleModels.Role & NameTranslated;

@autoinject
export class UserDetail {
  private allRoles: Array<TranslatedRole>;
  private userRoles: Array<NameTranslated>;
  private user: UserModels.User;
  private selectedRoleName: string;
  private roleNewFormVisible: boolean = false;

  originalObject: UserModels.User;

  protected availableRoles: Array<TranslatedRole>;

  protected isAdmin: boolean;
  protected isServiceUser: boolean;
  protected isProductionUser: boolean;
  protected isMooringUser: boolean;

  constructor(
    private errorService: ErrorService,
    private roleService: RoleService,
    private router: Router,
    private toastService: ToastService,
    private userService: UserService,
    private deleteDialogService: DeleteDialogService,
    private utility: Utility,
    private dialogService: DialogService,
    private i18n: I18N,
    protected producerService: ProducerService,
    protected serviceStationService: ServiceStationService,
    protected mooringStationService: MooringStationService
  ) {}

  attached() {
    this.roleService
      .getAll()
      .then((response) => {
        this.allRoles = response.map((it: TranslatedRole) => {
          it.NameTranslated = this.i18n.tr('role.' + it.Name);
          return it;
        });
        this.updateAvailableRoles();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  activate(params: { Id: string }) {
    this.userService
      .get(params.Id)
      .then((user) => {
        this.user = user;
        this.originalObject = JSON.parse(JSON.stringify(this.user));
      })
      .catch((err) => this.errorService.handleError(err));

    this.getUserRoles(params.Id);
  }

  private getUserRoles(id: string) {
    this.userService
      .getUserRoles(id)
      .then((userRoles) => {
        this.userRoles = userRoles.map((it) => {
          return {
            Name: it,
            NameTranslated: this.i18n.tr('role.' + it),
          };
        });

        this.updateAvailableRoles();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateAvailableRoles() {
    if (this.userRoles && this.allRoles) {
      this.availableRoles = this.allRoles.filter((x) => this.userRoles.findIndex((w) => w.Name == x.Name) == -1);
    }
    if (this.userRoles) {
      this.isAdmin = this.userRoles.find((x) => x.Name === 'Administrator') != null;
      this.isServiceUser = this.userRoles.find((x) => x.Name === 'ServiceUser') != null;
      this.isProductionUser = this.userRoles.find((x) => x.Name === 'Production') != null;
      this.isMooringUser =
        this.userRoles.find((x) => x.Name === 'MooringUser' || x.Name === 'MooringAdministrator') != null;
    }
  }

  protected saveUser() {
    this.userService
      .put(this.user, this.user.Id)
      .then(() => {
        this.originalObject = null;
        this.toastService.showSuccess('users.updated');
        // this.router.navigateToRoute('user-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected deleteUser() {
    void this.deleteDialogService.confirmBeforeDelete(() => {
      this.userService
        .delete(this.user.Id)
        .then(() => {
          this.originalObject = null;
          this.toastService.showSuccess('users.deleted');
          this.router.navigateToRoute('user-list');
        })
        .catch((err) => this.errorService.handleError(err));
    });
  }

  protected addRole() {
    // TODO: Add warning/confirm if adding administrator role?
    if (!this.selectedRoleName) {
      alert('Velg en rolle først');
      return;
    }

    this.roleService
      .addUserRole(this.user.Id, this.selectedRoleName)
      .then(() => {
        this.getUserRoles(this.user.Id);
        this.selectedRoleName = null;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected removeRole(role: TranslatedRole) {
    this.roleService
      .removeUserRole(this.user.Id, role.Name)
      .then(() => {
        this.getUserRoles(this.user.Id);
      })
      .catch((err) => {
        this.errorService.handleError(err);
      });
  }

  canDeactivate() {
    if (
      this.originalObject &&
      (!this.utility.areEqual(this.user, this.originalObject) || this.roleNewFormVisible === true)
    ) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel' },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'dialog.continue' },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            return true;
          }
        });
    } else {
      return true;
    }
  }
}
