import { GeneralOfferService } from './../../services/general-offer-service';
import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, BindingEngine } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { UserService } from 'services/user-service';
import { WeekReportService } from 'services/week-report-service';
import { Utility } from 'utility';
import * as moment from 'moment';

@autoinject
export class WeekReportEdit {

  private weekReport: Models.WeekReport;
  private originalObject: any;
  private saving: boolean = false;
  private locked: boolean = false;
  private hasNotApprovedPreviousPeriods: boolean = true;
  private previousWeekReportId: number;
  private nextWeekReportId: number;

  private generalOffers: Array<Models.GeneralOffer>;
  private sumQuantity: number;
    private sumValue: number;
    private sumMargin: number;

  constructor(
    private errorService: ErrorService,
    private utility: Utility,
    private dialogService: DialogService,
    private prompt: Prompt,
    private weekReportService: WeekReportService,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private userService: UserService,
    private router: Router,
    private generalOfferService: GeneralOfferService,
    private bindingEngine: BindingEngine
  ) {

  }

  private activate(params) {
    this.getWeekReport(params.Id);
    this.previousWeekReportId = null;
    this.nextWeekReportId = null;
    this.weekReport = null;
  }

  private canDeactivate() {

    if (!this.originalObject || this.saving || !this.weekReport['CanEdit']) {
      return true;
    }

    if (!this.utility.areEqual(this.weekReport, this.originalObject, true)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: {
          header: 'dialog.subFormOpenHeading',
          message: 'dialog.subFormOpenMessage',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel'  },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.continue'  }
          }
        }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  }

  private getWeekReport(weekReportId) {
    this.weekReport = null;
    this.generalOffers = null;

    this.weekReportService
    .get(weekReportId)
    .then((res: any) => {
      if (!res.BacklogEndOfPeriod) {
        res.BacklogEndOfPeriod = 0;
      }
      
      this.weekReport = res;
      
      this.originalObject = JSON.parse(JSON.stringify(res));
      this.locked = res.ConfirmedDate ? true : false;
      this.hasNotApprovedPreviousPeriods = res.HasNotApprovedPreviousPeriods;

      if (res.CanEdit === false) {
        this.locked = true;
      }

      this.getPreviousAndNextWeek();
      this.attachCalculationBinders();
      this.calculateWeekReport();

      this.getGeneralOffers();
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getGeneralOffers() {   

    let filterString = '$filter=InternalDepartmentId eq ' + this.weekReport.InternalDepartmentId;
    
    let firstDateInWeek = moment.default().year(this.weekReport.ReportYear).week(this.weekReport.ReportWeek).day('monday').format('YYYY-MM-DD');
    let firstDateNextWeek = moment.default().year(this.weekReport.ReportYear).week(this.weekReport.ReportWeek).day('monday').add(7, 'days').format('YYYY-MM-DD');

    filterString += ` and date(DateTransferredToOrder) gt ${firstDateInWeek} and date(DateTransferredToOrder) lt ${firstDateNextWeek}`;

    this.generalOfferService
    .getAll('?' + filterString)
    .then((res) => {
      this.generalOffers = res;

      this.sumMargin = res.reduce((sum, offer) => sum + (offer.Margin || 0), 0);
      this.sumValue = res.reduce((sum, offer) => sum + (offer.Value || 0), 0);
      this.sumQuantity = res.reduce((sum, offer) => sum + (offer.Quantity || 0), 0);        
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private saveWeekReport() {
    delete this.weekReport.Segment;
    delete this.weekReport.InternalDepartment;
    this.weekReportService
    .put(this.weekReport, this.weekReport.Id)
    .then((res) => {
      this.getWeekReport(this.weekReport.Id);
      this.toastService.showSuccess('weekReport.saved');
      this.eventAggregator.publish('weekReportListReset');
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private confirmWeekReport() {
    this.userService
    .getCurrentUser()
    .then((res) => {
      this.weekReport.ConfirmedDate = new Date();
      this.weekReport.ConfirmedByUserId = res.Id;
      delete this.weekReport.Segment;
      delete this.weekReport.InternalDepartment;
      this.weekReportService
      .put(this.weekReport, this.weekReport.Id)
      .then((ress) => {
        this.toastService.showSuccess('weekReport.confirmed');
        this.getWeekReport(this.weekReport.Id);
      })
      .catch((err) => this.errorService.handleError(err));

    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getPreviousAndNextWeek() {

    const previousWeekNumber = this.weekReport.ReportWeek == 1 ? 52 : this.weekReport.ReportWeek - 1;
    const nextWeekNumber = this.weekReport.ReportWeek == 52 ? 1 : this.weekReport.ReportWeek + 1;
    const filterString = `?$filter=InternalDepartmentId eq ${this.weekReport.InternalDepartmentId} and (ReportWeek eq ${previousWeekNumber} or ReportWeek eq ${nextWeekNumber})`;

    this.weekReportService
    .getAll(filterString)
    .then((res) => {

      const previousWeekReport = res.find((x) => x.ReportWeek == previousWeekNumber);
      const nextWeekReport = res.find((x) => x.ReportWeek == nextWeekNumber);

      if (previousWeekReport) {
        this.previousWeekReportId = previousWeekReport.Id;
      }

      if (nextWeekReport) {
        this.nextWeekReportId = nextWeekReport.Id;
      }

    })
    .catch((err) => this.errorService.handleError(err));

  }

  private goToPreviousWeekReport() {
    this.router.navigateToRoute('week-report-edit', { Id: this.previousWeekReportId });
  }

  private goToNextWeekReport() {
    this.router.navigateToRoute('week-report-edit', { Id: this.nextWeekReportId });
  }

  private attachCalculationBinders() {
    this.bindingEngine
    .propertyObserver(this.weekReport, 'NewOrdersInPeriod')
    .subscribe((newValue, oldValue) => {
      this.calculateWeekReport();
    });
    this.bindingEngine
    .propertyObserver(this.weekReport, 'InvoicedInPeriod')
    .subscribe((newValue, oldValue) => {
      this.calculateWeekReport();
    });
    this.bindingEngine
    .propertyObserver(this.weekReport, 'BacklogAdjustments')
    .subscribe((newValue, oldValue) => {
      this.calculateWeekReport();
    });
  }

  private calculateWeekReport() {

    if (this.weekReport.BacklogStartOfPeriod) {
      this.weekReport.BacklogEndOfPeriod = parseInt(<any>this.weekReport.BacklogStartOfPeriod);
    } else {
      this.weekReport.BacklogEndOfPeriod = 0;
    }

    if (this.weekReport.NewOrdersInPeriod) {
      this.weekReport.BacklogEndOfPeriod = parseInt(<any>this.weekReport.BacklogEndOfPeriod) + parseInt(<any>this.weekReport.NewOrdersInPeriod);
    }

    if (this.weekReport.InvoicedInPeriod) {
      this.weekReport.BacklogEndOfPeriod = parseInt(<any>this.weekReport.BacklogEndOfPeriod) - parseInt(<any>this.weekReport.InvoicedInPeriod);
    }

    if (this.weekReport.BacklogAdjustments) {
      this.weekReport.BacklogEndOfPeriod = parseInt(<any>this.weekReport.BacklogEndOfPeriod) + parseInt(<any>this.weekReport.BacklogAdjustments);
    }
  }

}
